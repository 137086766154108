import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { firestore } from "./config";

//#region Sports Centres

export async function getSportsCentre(uid: string): Promise<any | undefined> {
  try {
    const ref = doc(firestore, `sportsCentres/${uid}`);
    const snapshot = await getDoc(ref);

    return {
      ...snapshot.data(),
      id: snapshot.id,
    };
  } catch (err) {
    return undefined;
  }
}

export async function updateSportsCentre(uid: string, data: any): Promise<boolean> {
  try {
    const ref = doc(firestore, `sportsCentres/${uid}`);
    await setDoc(ref, data, { merge: true });

    return true;
  } catch (err) {
    return false;
  }
}

//#endregion

export async function getSports(): Promise<any[]> {
  try {
    const ref = collection(firestore, "sports");
    const snapshot = await getDocs(ref);

    return snapshot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
  } catch (err) {
    return [];
  }
}
