import React from "react";

import { useNavigate } from "react-router-dom";
import { signInWithPopup, signInWithRedirect, signOut, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { BsApple, BsGoogle } from "react-icons/bs";

import { auth, firestore } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";

export function SignInWithGoogle() {
  const signIn = async () => {
    await signInWithPopup(auth, new GoogleAuthProvider());
    const uid = auth.currentUser!.uid;
    const reference = doc(firestore, `profiles/${uid}`);
    await setDoc(reference, { isSportsCentre: true }, { merge: true });
  };

  return (
    <button className="btn btn-custom mb-2 login-button" onClick={signIn}>
      <BsGoogle />
      <span style={{ flex: 1 }}>Entrar con Google</span>
    </button>
  );
}

export function SignInWithApple() {
  const signIn = async () => {
    await signInWithRedirect(auth, new OAuthProvider("apple.com"));
    const uid = auth.currentUser!.uid;
    const reference = doc(firestore, `profiles/${uid}`);
    await setDoc(reference, { isSportsCentre: true }, { merge: true });
  };

  return (
    <button className="btn btn-custom mb-2 login-button" onClick={signIn}>
      <BsApple />
      <span style={{ flex: 1 }}>Entrar con Apple</span>
    </button>
  );
}

export function SignOutUser() {
  const navigate = useNavigate();

  const signOutAndRedirect = async () => {
    signOut(auth);
    navigate("/");
  };

  return (
    <button className="btn btn-custom" onClick={() => signOutAndRedirect()}>
      Salir
    </button>
  );
}
