import React, { useEffect, useState } from "react";
import { capitalize, fetchFromAPI } from "../helpers";

import "../css/StripeCallback.css";

function SubscriptionSuccess() {
  const url = window.location.href;
  const sessionId = new URL(url).searchParams.get("session_id");

  const [session, setSession] = useState<any>(undefined);

  useEffect(() => {
    async function fetchSession() {
      const data = await fetchFromAPI(`checkout?id=${sessionId}`, { method: "GET" });

      setSession(data);
    }
    fetchSession();
  }, []);

  const showSession = session && ["active", "trialing"].includes(session.subscription_details.status);

  return (
    <div className="stripe-callback container py-5 my-5 d-flex flex-column justify-content-center align-items-center">
      <h1>¡Enhorabuena!</h1>
      <p>Te has suscrito con éxito. Gracias por confirar en Sports Buddy.</p>
      {!session && (
        <div className="py-5">
          <div className="spinner-border text-dark" role="status"></div>
        </div>
      )}
      {showSession && (
        <table className="table my-5">
          <tbody>
            <tr>
              <th scope="row">Suscripción</th>
              <td>{capitalize(session.metadata.subscription)}</td>
            </tr>
            <tr>
              <th scope="row">Precio</th>
              <td>{session.subscription_details.items.data[0].plan.amount / 100}€</td>
            </tr>
            <tr>
              <th scope="row">Periodo de prueba</th>
              <td>{session.subscription_details.items.data[0].plan.trial_period_days} días</td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SubscriptionSuccess;
