import { configureStore } from "@reduxjs/toolkit";
import sportsCentreReducer from "./features/sportsCentre/sportsCentreSlice";
import sportsReducer from "./features/sports/sportsSlice";
import { useDispatch } from "react-redux";

const store = configureStore({
  reducer: {
    sportsCentre: sportsCentreReducer,
    sports: sportsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
