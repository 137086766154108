import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSportsCentre, updateSportsCentre } from "../../firebase/firestore";

// Interfaces
export interface iLocation {
  place: string;
  lat: number;
  lng: number;
}

export interface iSportsCentre {
  fetched: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
  data: any;
}

// Initial state
const initialState: iSportsCentre = {
  fetched: false,
  loading: false,
  success: false,
  error: false,
  data: {},
};

// Thunks
export const loadSportsCentre = createAsyncThunk("sportsCentre/loadSportsCentre", async (uid: string) => {
  const data = await getSportsCentre(uid);

  if (!data) {
    throw "Error fetching Sports Centre";
  }

  return data;
});

export const editSportsCentre = createAsyncThunk(
  "sportsCentre/editSportsCentre",
  async ({ uid, data }: { uid: string; data: any }) => {
    const success: boolean = await updateSportsCentre(uid, data);

    if (!success) {
      throw "Error updating Sports Centre";
    }

    return data;
  }
);

// Slice
export const sportsCentreSlice = createSlice({
  name: "sportsCentre",
  initialState,
  reducers: {
    hideError: (state, action) => {
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSportsCentre.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(loadSportsCentre.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.fetched = true;
      state.data = action.payload;
    });
    builder.addCase(editSportsCentre.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(editSportsCentre.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.data = { ...state.data, ...action.payload };
    });
    builder.addCase(editSportsCentre.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

// Exports
export const { hideError } = sportsCentreSlice.actions;

const reducer = sportsCentreSlice.reducer;
export default reducer;
