import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSports } from "../../firebase/firestore";

// Interfaces
export interface iSport {
  id: string;
  name: string;
  translations: any;
  category: string;
  png: string;
  numberId: number;
}

export interface iSports {
  fetched: boolean;
  loading: boolean;
  error: boolean;
  data: iSport[];
}

// Initial state
const initialState: iSports = {
  fetched: false,
  loading: false,
  error: false,
  data: [],
};

// Thunks
export const loadSports = createAsyncThunk("sports/loadSports", async () => {
  const data = await getSports();
  return data;
});

// Slice
export const sportsSlice = createSlice({
  name: "sports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSports.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(loadSports.fulfilled, (state, action) => {
      state.loading = false;
      state.error = false;
      state.fetched = true;
      state.data = action.payload;
    });
  },
});

// Exports
export const {} = sportsSlice.actions;

const reducer = sportsSlice.reducer;
export default reducer;
