import React from "react";

import { SignOutUser } from "../components/authButtons";

import "../css/Account.css";
import SportsCentreForm from "../components/sportsCentreForm";
import { useProfile } from "../Context/profile-context";

function Account({ user }: { user: any }) {
  const profile = useProfile();

  return (
    <div className="container mt-5 pt-5">
      <SportsCentreForm user={user} />

      <div className="mb-5">
        <h3 className="mb-3">Cuenta</h3>
        <div className="account-box">
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">Email</th>
                <td>{user.email}</td>
              </tr>
              <tr>
                <th scope="row">Nombre</th>
                <td>{profile.name}</td>
              </tr>
            </tbody>
          </table>
          <SignOutUser />
        </div>
      </div>
    </div>
  );
}

export default Account;
