import React, { useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { useSigninCheck } from "reactfire";

import Navbar from "./components/navbar";

import Login from "./pages/Login";
import Home from "./pages/Home";
import Account from "./pages/Account";
import SubscriptionSuccess from "./pages/SubscriptionSuccess";
import SubscriptionCancel from "./pages/SubscriptionCancel";
import Footer from "./components/footer";
import Loading from "./components/loading";

function App() {
  const { status, data: signInCheckResult } = useSigninCheck();
  const location = useLocation();

  if (status === "loading") {
    return <Loading />;
  }

  return (
    <>
      {location.pathname !== "/login" && <Navbar />}

      <main>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/account"
            element={
              signInCheckResult.signedIn ? <Account user={signInCheckResult.user} /> : <Navigate replace to="/login" />
            }
          />
          <Route path="/subscription-success" element={<SubscriptionSuccess />} />
          <Route path="/subscription-cancelled" element={<SubscriptionCancel />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </main>

      {location.pathname !== "/login" && <Footer />}
    </>
  );
}

export default App;
