import React, { useMemo, createContext, useContext, useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useUser } from "reactfire";
import { firestore } from "../firebase/config";
import iProfile from "../Models/profile";

const emptyProfile: iProfile = {
  uid: "",
  name: "",
  image512: "",
};

// A context has a provider and a consumer
const ProfileContext = createContext<iProfile>(emptyProfile);

// The provider
export function ProfileProvider(props: any) {
  const { data: user } = useUser();
  const [profile, setProfile] = useState<iProfile>(emptyProfile);

  useEffect(() => {
    const getProfile = async () => {
      const uid: string = user!.uid;

      const snap = await getDoc(doc(firestore, `profiles/${uid}`));
      const data = snap.data();

      setProfile({
        uid: uid,
        name: data ? data.name : "",
        image512: data ? data.image512 : "",
      });
    };

    if (user) {
      getProfile();
    }
  }, [user]);

  const value = useMemo(() => {
    return profile;
  }, [profile]);

  return <ProfileContext.Provider value={value} {...props} />;
}

// The consumer
export function useProfile() {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error("useProfile has to be inside the provider ProfileContext");
  }

  // Returns the value from the provider
  return context;
}
