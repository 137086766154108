import React, { useEffect } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "reactfire";

import { SignInWithGoogle, SignInWithApple } from "../components/authButtons";

import "../css/Login.css";
import logo from "../assets/img/logo.png";

function Login() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (user.data) {
      console.log(searchParams.get("returnHome"));
      if (searchParams.get("returnHome")) navigate("/");
      else navigate("/account");
    }
  }, [user]);

  return (
    <div className="row d-flex justify-content-center align-items-center login">
      <div className="col-lg-6">
        <div className="p-5 d-flex flex-column align-items-center">
          <div className="text-center">
            <Link to={"/"}>
              <img src={logo} className="mb-3 login-logo" alt="Sports Buddy logo" />
            </Link>
            <h4 className="mt-1 pb-1">Sports Buddy</h4>
            <p className="mt-1 mb-5 pb-1">Identifícate y administra tu centro deportivo desde la web.</p>
          </div>

          <SignInWithGoogle />
          <SignInWithApple />
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center text-container">
        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
          <h4 className="mb-4 gold-text slogan">EL DEPORTE ES PARA TODOS</h4>
          <p className="quote mb-0">
            Bienvenido a la primera comunidad online de deportistas pensada para todos los deportes y niveles.
          </p>

          <iframe
            className="video mt-5"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vMKNDinz-ZI"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Login;
