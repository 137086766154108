import { auth } from "./firebase/config";

// const API = "http://localhost:3333";
const API = "https://stripe-backend-wxvs7xfubq-uc.a.run.app";

export function scrollTo(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView();
  }
}

export function capitalize(value: string) {
  return value[0].toUpperCase() + value.substring(1);
}

/**
 * A helper function to fetch data from the API
 */
export async function fetchFromAPI(endpoint: String, opts: any) {
  const requestObject = { method: "POST", body: null, ...opts };
  const { method, body } = requestObject;

  const user = auth.currentUser;
  const token = user && (await user.getIdToken());

  const res = await fetch(`${API}/${endpoint}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

/**
 * A function that sorts Sports array by name
 */
export function compareSports(a: any, b: any) {
  if (a.translations["es"] < b.translations["es"]) {
    return -1;
  }
  if (a.translations["es"] > b.translations["es"]) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
