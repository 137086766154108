import React from "react";

function Loading() {
  return (
    <div className="container w-100 d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
      <div className="spinner-border text-dark" role="status"></div>
    </div>
  );
}

export default Loading;
