import React from "react";
import { Link } from "react-router-dom";

import "../css/StripeCallback.css";

function SubscriptionCancel() {
  return (
    <div className="stripe-callback container py-5 my-5 d-flex flex-column justify-content-center align-items-center">
      <h1>Pago cancelado</h1>
      <p className="text-center my-4">
        Hola buddy, tu pago no ha sido procesado. Lamentamos que no te hayan acabado de convencer las nuevas
        suscripciones de Sports Buddy. Puedes enviarnos cualquier comentario o sugerencia a{" "}
        <a href="mailto:info@sportsbuddy.org">info@sportsbuddy.org</a>.
      </p>

      <p className="text-center mb-2">Si ha sido un error, siempre puedes volver a probarlo. ¡Muchas gracias!</p>
      <Link to={"/"} className="btn btn-custom">
        Ver planes
      </Link>
    </div>
  );
}

export default SubscriptionCancel;
