import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";

import { RecoilRoot } from "recoil";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import store from "./store";
import { Provider } from "react-redux";

import { FirebaseAppProvider, AuthProvider, FirestoreProvider, StorageProvider } from "reactfire";
import { auth, firestore, storage } from "./firebase/config";
import { ProfileProvider } from "./Context/profile-context";
import ScrollToTop from "./components/ScrollToTop";

export const stripePromise = loadStripe(
  "pk_live_51Lk6OXIC2zuWzlBkwDeohSzE4ju1O62Q9FtMJf9cDy4yFpESivarVlsesnh8UeutMxfixAS06sqW4OenTxOaiOjf00EkzACO9W"
);

const firebaseConfig = {
  apiKey: "AIzaSyB4v_BZ4ZW__qknbwyMr39gZsEsF_EG1qw",
  authDomain: "auth.sportsbuddy.org",
  projectId: "sports-buddy-app",
  storageBucket: "sports-buddy-app.appspot.com",
  messagingSenderId: "504248774753",
  appId: "1:504248774753:web:a686b416de4306f86c3a20",
  measurementId: "G-NBLJY8KY0H",
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Provider store={store}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestore}>
              <StorageProvider sdk={storage}>
                {/* Making Stripe available to all the app */}
                <Elements stripe={stripePromise}>
                    <ProfileProvider>
                      <Router>
                        <ScrollToTop />
                        <App />
                      </Router>
                    </ProfileProvider>
                </Elements>
              </StorageProvider>
            </FirestoreProvider>
          </AuthProvider>
        </FirebaseAppProvider>
      </Provider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
