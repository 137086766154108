import React, { useState, useEffect } from "react";
import classNames from "classnames";

import { Link, useLocation } from "react-router-dom";
import { useUser } from "reactfire";

import logo from "../assets/img/logo.png";
import { useProfile } from "../Context/profile-context";

export default function Navbar() {
  const { data: user } = useUser();
  const profile = useProfile();
  const [pathname, setPathname] = useState(window.location.pathname);
  const [scrolled, setScrolled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const showProfile = profile && user;

  return (
    <nav
      className={classNames({
        "navbar fixed-top navbar-expand-lg bg-light": true,
        shadow: scrolled,
      })}
    >
      <div className="container container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Sports Buddy logo" width="30" height="30" className="d-inline-block align-text-top" />
        </Link>

        <div className="ml-auto">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {showProfile && (
              <li className="nav-item dropdown">
                <Link
                  className={classNames({
                    "nav-link": true,
                    active: pathname === "/account",
                  })}
                  to="/account"
                >
                  {profile.name || "Cuenta"}
                  &nbsp;&nbsp;&nbsp;
                  {profile.image512 != "" && (
                    <img
                      src={profile.image512}
                      className="rounded-circle"
                      height="30px"
                      width="30px"
                      alt={profile.name}
                    />
                  )}
                </Link>
              </li>
            )}
            {!showProfile && (
              <li className="nav-item">
                <Link
                  className={classNames({
                    "nav-link": true,
                    active: pathname === "/login",
                  })}
                  to="/login"
                >
                  Identifícate
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
