import React from "react";
import { BsGear, BsInfoCircle, BsImage, BsClock, BsSliders } from "react-icons/bs";
import qrCode from "../assets/qr-code.png";

export default function SportsCentreForm({ user }: { user: any }) {
  return (
    <div className="mb-5">
      <h3 className="mb-3">Tu perfil de centro deportivo</h3>
      <div className="account-box">
        <div className="row">
          <div className="col-md-6">
            <p>Para completar tu perfil de centro deportivo sigue los siguientes pasos:</p>
            <ol>
              <li>Descarga la app, puedes usar el QR.</li>
              <li>
                Identifícate con el email que has usado para la suscripción: <b>{user.email}</b>
              </li>
              <li>
                Una vez dentro, en el menú de abajo, haz click en ajustes <BsGear className="icon" />.
              </li>
              <li>Rellena:</li>
              <ul>
                <li>
                  Información básica del centro <BsInfoCircle className="icon" />
                </li>
                <li>
                  Imágenes <BsImage className="icon" />
                </li>
                <li>
                  Horarios de apertura <BsClock className="icon" />{" "}
                </li>
                <li>
                  Deportes <BsSliders className="icon" />
                </li>
              </ul>
            </ol>
          </div>
          <div className="col-md-6 text-center">
            <h4>Escanear para descargar</h4>
            <img src={qrCode} alt="Download Sports Buddy QR code" width={200} height={200} />
          </div>
        </div>
      </div>
    </div>
  );
}
