import React from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { BsGeo, BsBullseye, BsPhoneVibrate, BsInfoCircle } from "react-icons/bs";

import phones from "../assets/img/home1.png";
import sportsCentreImg from "../assets/img/sportsCentre.png";
import "../css/Home.css";
import { scrollTo } from "../helpers";

function Home() {
  return (
    <div>
      {/* Hero section */}
      <section className="hero d-flex align-items-center">
        <div className="container">
          <div className="d-block d-md-none py-sm-5 py-3"></div>
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true} delay={300}>
                <h1>La revolución del deporte</h1>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true} delay={600}>
                <h3>
                  Expande tu negocio con las nuevas <span className="gold-text">funcionalidades</span> de Sports Buddy
                </h3>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} delay={900}>
                <button className="btn btn-custom mb-4" onClick={() => scrollTo("details")}>
                  ¡Vamos!
                </button>
              </AnimationOnScroll>
            </div>

            <div className="col-md-6 d-flex justifty-content-center align-items-center">
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} delay={900}>
                <img src={phones} alt="Sports Buddy home screen" className="phones" />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      {/* Counters section */}
      <section className="items d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 text-center item">
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={300}>
                <div className="wrapIcon white-shadow">
                  <BsGeo className="icon" />
                </div>
                <h3 className="py-3">
                  Cerca de tu <br /> negocio
                </h3>
                <p>
                  Anúnciate solo a los usuarios de tu alrededor, enseñaremos tu perfil dentro de un radio de búsqueda
                  que tu eliges.
                </p>
              </AnimationOnScroll>
            </div>

            <div className="col-lg-4 col-md-4 text-center item">
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={600}>
                <div className="wrapIcon white-shadow">
                  <BsBullseye className="icon" />
                </div>
                <h3 className="py-3">
                  Efectividad y <br /> precisión
                </h3>
                <p>Selecciona tus deportes y anúnciate solo a los usuarios que les pueda interesar tu servicio.</p>
              </AnimationOnScroll>
            </div>

            <div className="col-lg-4 col-md-4 text-center item">
              <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} delay={1200}>
                <div className="wrapIcon white-shadow">
                  <BsPhoneVibrate className="icon" />
                </div>
                <h3 className="py-3">
                  No te pierdas <br /> nada
                </h3>
                <p>
                  Recibe notificaciones en tiempo real, interactúa con la comunidad y habla con tus clientes gracias a
                  nuestro chat in-app.
                </p>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      {/* Sports Centres plans details */}
      <section className="plan-details plan-details" id="details">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-8 order-lg-2">
              <div className="section-heading">
                <h6>Beneficios para</h6>
                <h3>Centros deportivos</h3>
                <p>
                  Haz crecer tu negocio anunciándote como centro deportivo, club o asociación. Publica notícias o
                  eventos.
                </p>
                <div className="bigline"></div>
              </div>
              <p>
                Este plan está pensado para centros deportivos, asociaciones o clubs que quieran aprovechar un canal de
                marketing más específico, moderno y eficaz.
              </p>
              <ul className="">
                <li>
                  <BsInfoCircle className="details-icon" />
                  Edita tu perfil como centro deportivo desde nuestra página web.
                </li>
                <li>
                  <BsInfoCircle className="details-icon" />
                  Muéstrate como centro deportivo a los usuarios de tu alrededor.
                </li>
                <li>
                  <BsInfoCircle className="details-icon" />
                  Publica notícias o eventos.
                </li>
                <li>
                  <BsInfoCircle className="details-icon" />
                  Enseñaremos tu perfil en varias pantallas.
                </li>
                <li>
                  <BsInfoCircle className="details-icon" />
                  Habla con tus clientes sin necesidad de salir de la app con nuestro sistema de chat.
                </li>
                <li>
                  <BsInfoCircle className="details-icon" />
                  Administra tu suscripción y contacta con soporte fácilmente desde nuestra página web.
                </li>
              </ul>
            </div>
            <div className="col-lg-4 order-lg-1 text-center">
              <img src={sportsCentreImg} alt="Peril de centro deportivo" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
