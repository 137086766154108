import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB4v_BZ4ZW__qknbwyMr39gZsEsF_EG1qw",
  authDomain: "auth.sportsbuddy.org",
  projectId: "sports-buddy-app",
  storageBucket: "sports-buddy-app.appspot.com",
  messagingSenderId: "504248774753",
  appId: "1:504248774753:web:a686b416de4306f86c3a20",
  measurementId: "G-NBLJY8KY0H",
};

// Init firebase
const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { auth, firestore, functions, storage };
